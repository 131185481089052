@keyframes marquee {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: inline-block;
  animation-name: marquee;
  animation-duration: var(--animate-marquee-duration, 30s);
  animation-timing-function: var(--animate-marquee-timing, linear);
  animation-iteration-count: var(--animate-marquee-iteration-count, infinite);
}
