@import "@/styles/base";

.landing_head {
  position: relative;
  isolation: isolate;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: spacing(12);
  max-height: 600px;
  height: 458px;

  @include mobile-only {
    height: 300px;
    padding: spacing(6);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to top,
      theme(background, app) 0%,
      rgba(theme(neutral, 4), 0.5) 20%,
      40%,
      rgba(255, 255, 255, 0)
    );
    z-index: -1;
    pointer-events: none;
  }
}

.video_bg {
  position: absolute;
  inset: 0;
  object-fit: cover;
  z-index: -2;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  max-width: 100%;
  border-radius: radius(2);
  pointer-events: none;
}

.epic_adventure {
  pointer-events: none;
  font-family: var(--font-integral-cf);
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  position: absolute;
  text-transform: uppercase;
  white-space: nowrap;
  bottom: 2rem;
  left: -200%;

  span {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
  }
}

.alternate {
  -webkit-text-stroke-color: #eb3a4a4d !important;
}

.content {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: spacing(4);
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: spacing(8);

  @include mobile-only {
    align-items: center;
    text-align: center;
    .swiper-explore {
      gap: spacing(3);
      width: 100%;
    }
  }
}

.content_title {
  display: flex;
  align-items: center;
  gap: spacing(2);
  font-family: var(--font-integral-cf);
  font-size: 1.5rem;

  @include mobile-only {
    margin-top: spacing(2);
    font-size: 12px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.swiper {
  margin-block-start: spacing(4);
}