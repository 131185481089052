@import '@/styles/base';
.logo_text {
    color: theme(text-color, main);
    font-family: var(--font-lexend);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
}

.logo {
    border-radius: 99999px;
    overflow: clip;
}
.my_base {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: spacing(5);
}

.stat_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.title {
    color: theme(neutral, 50);
    font-family: var(--font-lexend);
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
}

.value {
    color: theme(text-color, main);
    font-family: var(--font-lexend);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
}

.stats {
    display: flex;
    gap: 1rem;
}