@import "@/styles/base";

.carousel_track {
    display: flex;
    align-items: center;
    width: 1200px;
}
.carousel_slider {
    max-width: max-content;
}
.dots_container {
    display: flex;
    gap: spacing(1);
}
.dots {
    width: 0.5rem;
    height: 0.5rem;
    background-color: theme(neutral, 24);
    display: block;
}
.carousel_header {
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding-block: spacing(2);
    position: sticky;
    left: 0;
}
.carousel_item_container_overflow  {
    overflow-x: clip;
}
.carousel_item_container {
    position: relative;
}
.active {
    background-color: theme(brand, main);
    width: 2rem;
}
.next {
    background-color: transparentize($color: theme(brand, main), $amount: .78);
    filter: backdrop(16px);
    border-radius: radius(9999);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(50%);
    z-index: 4;
}

.prev {
    background-color: transparentize($color: theme(brand, main), $amount: .78);
    filter: backdrop(16px);
    border-radius: radius(9999);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 4;
    & svg {
        rotate: 180deg
    }
}

.disabled {
    visibility: hidden;
    pointer-events: none    ;
}

.carousel_item_wrapper_1 {
    width: 100%;
}
.carousel_item_wrapper_2 {
    width: 50%;
}