@import "@/styles/base";

.community {
    width: 100%;
    position: relative;
    isolation: isolate;
    border-radius: radius(2);
    overflow: hidden;
    height: 11.5rem;
    display: block;
    color: theme(text-color, main);
    text-decoration: none;
    font-weight: 700;
}

.bg {
    object-fit: cover;
    object-position: center;
    z-index: -1;
}
.community_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: spacing(6);
    gap: spacing(6);
}

.community_name {
    color: theme(text-color, main);
    font-family: var(--font-lexend);

    /* Lexend/Body/Bold/L */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
}
.community_text {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: spacing(2);
}

.description {
    font-size: 14px;
    font-weight: normal;
}
.static_logo {
    border-radius: 99999px;
}