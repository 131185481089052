@import "@/styles/base";

.container {
    display: grid;
    max-width: min($container-max-width, 100vw);
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    gap: 1rem;

    @include mobile-only {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

.head {
    display: grid;
    max-width: min($container-max-width, 100vw);
    grid-template-columns: 1fr;
    width: 100%;
}