$container-max-width: 1440px;
$container-breakpoint-xl: 780px;
$container-breakpoint-lg: 1200px;
$container-breakpoint-l: 704px;

$xxs-max: 480px; // Set xxs breakpoint's max width
$xs-max: 576px; // Set xs breakpoint's max width
$sm-max: 768px; // Set sm breakpoint's max width
$md-max: 992px; // Set md breakpoint's max width
$lg-max: 1200px; // Set lg breakpoint's max width
$xl-max: 1400px; // Set xl breakpoint's max width
$xxxl-max: 2580px;

$sm-start: ($xs-max + 1); // Generate sm breakpoint's min width
$md-start: ($sm-max + 1); // Generate md breakpoint's min width
$lg-start: ($md-max + 1); // Generate lg breakpoint's min width
$xl-start: ($lg-max + 1); // Generate xl breakpoint's min width
$xxl-start: ($xl-max + 1); // Generate xxl breakpoint's min width
$xxxl-start: ($xxxl-max + 1); // 2k monitors


// Small mobile only
@mixin small-mobile-only {
  @media screen and (max-width: $xxs-max) {
    @content;
  }
}


// Mobile only
@mixin mobile-only {
  @media screen and (max-width: $sm-max) {
    @content;
  }
}

// Tablet only
@mixin tablet-only {
  @media screen and (min-width: $md-start) and (max-width: $lg-max) {
    @content;
  }
}

// Tablet and larger
@mixin tablet-up {
  @media screen and (min-width: $md-start) {
    @content;
  }
}


// Small desktop and larger
@mixin desktop-up {
  @media screen and (min-width: $sm-max) {
    @content;
  }
}

// Small desktop only
@mixin desktop-only {
  @media screen and (min-width: $sm-max) and (max-width: $lg-max) {
    @content;
  }
}

// Large desktop and larger
@mixin large-desktop-up {
  @media screen and (min-width: $lg-max) {
    @content;
  }
}

// Large desktop only
@mixin large-desktop-only {
  @media screen and (min-width: $lg-max) and (max-width: $xl-max) {
    @content;
  }
}

// Extra large desktop and larger
@mixin extra-large-desktop-up {
  @media screen and (min-width: $xl-max) {
    @content;
  }
}

// Extra large desktop only
@mixin extra-large-desktop-only {
  @media screen and (min-width: $xl-max) and (max-width: $xxxl-max) {
    @content;
  }
}

// 2K monitors and larger
@mixin ultra-hd-up {
  @media screen and (min-width: $xxxl-max) {
    @content;
  }
}
