@import "@/styles/base";

.hero_container {
  height: 100svh;
  overflow: hidden;
  position: relative;

  

  @include mobile-only {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(13, 13, 18, 0) 53.55%,
        #0d0d12 100%
      );
    }
  }
}

.video__background {
    position: absolute;
    inset: 0;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.1;
    pointer-events: none;
  }

  .video__hero {
    position: absolute;
    top: 0%;
    right: 5%;
    height: 100%;

    @include mobile-only {
      right: 50%;
      transform: translateX(50%);
    }
  }

  .content {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
.hero_title {
  font-family: var(--font-integral-cf);
  font-size: 3rem;
  line-height: 100%;
  @include mobile-only {
    font-size: 2rem;
    text-align: balance;
  }
}
.hero_description {
  font-size: 1rem;
  line-height: 125%;
  color: theme(neutral, 50);
  
  @include mobile-only {
    font-size: 0.75rem;
  }
}
.hero_content {
  display: flex;
  gap: spacing(2);
  flex-direction: column;
  align-items: flex-start;
  padding: spacing(12);

  @include mobile-only {
    padding: spacing(2);
    align-items: stretch;
  }
}