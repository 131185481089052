@import "@/styles/base";

.top_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.display_name {
    color: theme(text-color, main);
    font-family:  var(--font-lexend);
    font-size: 1rem;
    font-style: normal;
    line-height: 1.5rem;
    text-decoration: none;
}
.connected_socials {
    display: flex;
    gap: spacing(2);
}
.social_connect, .social_connect_text {
    display: flex;
    align-items: center;
}
.social_connect {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: spacing(4);
    @include mobile-only {
        flex-direction: column;
        gap: spacing(4);
    }
}
.social_connect_button {
    @include mobile-only {
        width: 100%;
    }
}
.social_connect_text {
    gap: spacing(2);
}