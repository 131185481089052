@import '@/styles/base';

.landing-page {
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  align-items: center;
  padding-left: calc(var(--sidebar-width) + spacing(6));
  padding-right: spacing(3);
  padding-top: spacing(6);

  @include mobile-only {
    padding-left: spacing(4);
    padding-right: spacing(4);
    padding-top: theme(sizes, navbar-height);
  }
}

.landing-page-overflow  {
  overflow-x: hidden 
}