@import "@/styles/base";

.landing_communities {
  padding: spacing(32) 0;
  background-color: theme(background, app);
  position: relative;
  z-index: 1;

  @include mobile-only {
    padding-block: spacing(12);
    padding-inline: spacing(4);
  }
}

.join_title {
  font-size: 3rem;
  font-family: var(--font-integral-cf);
  width: 50%;

  @include mobile-only {
    width: 100%;
    padding-inline: spacing(2);
    font-size: 1.5rem;
  }
}

.or {
  font-family: var(--font-integral-cf);
  font-size: 24px;
}
.left {
  display: flex;
  flex-direction: column;
  gap: spacing(6);
  align-items: flex-start;
  flex-basis: 50%;

  @include mobile-only {
    flex-direction: column;
    gap: spacing(4);
    text-align: center;
    align-items: center;
  }
}

.right {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  gap: spacing(6);
}

.content_wrapper {
  display: flex;
  max-width: $container-breakpoint-xl;
  width: 100%;
  margin-inline: auto;
  gap: spacing(4);

  @include mobile-only {
    flex-direction: column;
    gap: spacing(12);
  }
}

.create_community > span {
  display: flex;
  gap: spacing(2);
  align-items: center;
}

.create_community {
  @include mobile-only {
    padding: spacing(4) !important;
  }
}